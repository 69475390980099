import React from 'react';
import PropTypes from 'prop-types';

import { WebRTCAdaptor } from '../../utils/vendor/webrtc-adaptor';

import './Conference.scss';
import classnames from 'classnames';

class Conference extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            streams: [],
            streamInfo: {},
        };

        this.localVideoRef = React.createRef();
        this.roomOfStream = [];
        this.streamsList = [];
        this.publishStreamId = undefined;

        this.remoteVideoRefs = {};
        this.streams = {};
    }

    componentDidMount() {
        const { websocketURL, roomName } = this.props;
        if (websocketURL && roomName) {
            this.initialiseConference();
        }
    }

    componentDidUpdate = (prevProps) => {
        const { websocketURL, roomName } = this.props;
        if (
            prevProps.websocketURL !== websocketURL ||
            prevProps.roomName !== roomName
        ) {
            // TODO: teardown previous
            this.initialiseConference();
        }

        const streamIds = [];
        this.state.streams.forEach((streamId) => {
            streamIds.push(streamId);
            if (
                this.remoteVideoRefs[streamId] &&
                this.streams[streamId] &&
                !this.remoteVideoRefs[streamId].srcObject
            ) {
                this.remoteVideoRefs[streamId].srcObject =
                    this.streams[streamId].stream;
                try {
                    this.remoteVideoRefs[streamId].play();
                } catch (e) {
                    console.error(e);
                }
            }
        });

        Object.keys(this.streams).forEach((k) => {
            if (!streamIds.includes(k)) {
                delete this.streams[k];
                delete this.remoteVideoRefs[k];
                this.props.onStreamClosed(k);
            }
        });
    };

    componentWillUnmount() {
        clearInterval(this.roomUpdateInterval);
    }

    initialiseConference = () => {
        const { websocketURL, roomName } = this.props;

        var pc_config = {
            iceServers: [
                {
                    urls: 'stun:global.stun.twilio.com:3478?transport=udp',
                },
            ],
        };

        var sdpConstraints = {
            OfferToReceiveAudio: false,
            OfferToReceiveVideo: false,
        };

        var mediaConstraints = {
            video: true,
            audio: true,
        };

        const token = null; // TODO: security token

        // var isDataChannelOpen = false;
        // var isMicMuted = false;
        // var isCameraOff = false;
        // var roomTimerId = -1;

        let handledOnclose = false;

        this.webRTCAdaptor = new WebRTCAdaptor({
            websocket_url: websocketURL,
            mediaConstraints: mediaConstraints,
            peerconnection_config: pc_config,
            sdp_constraints: sdpConstraints,
            localVideoId: 'localVideo',
            isPlayMode: true,
            debug: false,

            callback: (info, obj) => {
                if (info === 'initialized') {
                    console.log('conference initialised!');
                    this.webRTCAdaptor.joinRoom(roomName, '');
                }

                if (info === 'joinedTheRoom') {
                    // console.log(info, obj);
                    var room = obj.ATTR_ROOM_NAME;
                    this.roomOfStream[obj.streamId] = room;

                    this.publishStreamId = obj.streamId;

                    // TODO: publish?

                    if (obj.streams) {
                        obj.streams.forEach((item) => {
                            this.webRTCAdaptor.play(item, token, roomName);
                        });
                        this.streamsList = obj.streams;
                    }

                    this.roomUpdateInterval = setInterval(() => {
                        this.webRTCAdaptor.getRoomInfo(
                            roomName,
                            this.publishStreamId
                        );
                    }, 1000);
                }

                if (info === 'newStreamAvailable') {
                    this.addConferenceVideo(obj);
                }

                if (info === 'closed') {
                    if (!handledOnclose) {
                        handledOnclose = true;
                        setTimeout(this.initialiseConference, 2000);
                    }
                }

                if (info === 'roomInformation') {
                    for (let str of obj.streams) {
                        if (!this.state.streams.includes(str)) {
                            this.webRTCAdaptor.play(str, token, roomName);
                        }
                    }
                    this.setState({ streams: obj.streams });
                }

                // if (info === 'streamInformation') {
                //     console.log('streamInformation', obj);
                // }
            },
            callbackError: (error, message) => {
                if (
                    [
                        'data_store_not_available',
                        'wserror',
                        'WebSocketNotConnected',
                        'ws_connection_failed',
                    ].includes(error)
                ) {
                    clearInterval(this.roomUpdateInterval);

                    if (error === 'data_store_not_available') {
                        this.webRTCAdaptor.closeWebSocket();
                    }

                    if (error === 'ws_connection_failed')
                        console.log(error, message);

                    if (!handledOnclose) {
                        handledOnclose = true;
                        setTimeout(this.initialiseConference, 2000);
                    }
                } else if (error === 'already_playing') {
                    // ignore
                } else {
                    console.trace('callbackError:', error);
                }
            },
        });
    };

    addConferenceVideo = ({ streamId, stream, ...obj }) => {
        this.streams[streamId] = { stream };
        window.testStream = stream;
    };

    setVideoRef = (streamId, ref) => {
        this.remoteVideoRefs[streamId] = ref;
        if (ref) {
            ref.addEventListener('loadedmetadata', () => {
                this.setDimensions(streamId, ref.videoWidth, ref.videoHeight);
            });
            ref.addEventListener('resize', () => {
                this.setDimensions(streamId, ref.videoWidth, ref.videoHeight);
            });
        }
    };

    setDimensions = (streamId, width, height) => {
        this.setState({
            streamInfo: {
                ...this.state.streamInfo,
                [streamId]: {
                    ...this.state.streamInfo[streamId],
                    width,
                    height,
                },
            },
        });

        this.props.onStreamInfo(streamId, [
            {
                streamWidth: width,
                streamHeight: height,
            },
        ]);
    };

    onSelect = (streamId) => {
        const ref = this.remoteVideoRefs[streamId];
        this.props.onSelect(streamId, ref.srcObject, [
            {
                streamWidth: ref.videoWidth,
                streamHeight: ref.videoHeight,
            },
        ]);
    };

    render() {
        const { streams, streamInfo } = this.state;
        const { focussedStreamIds } = this.props;

        return (
            <div className="Conference">
                <div className="feeds">
                    {streams.map((streamId) => {
                        const info = streamInfo[streamId];
                        let height = this.props.height;
                        let width = height;
                        if (info) {
                            let aspectRatio = info.width / info.height;
                            width = height * aspectRatio;
                        }
                        return (
                            <div
                                className={classnames('VideoStream', {
                                    hidden: focussedStreamIds.includes(
                                        streamId
                                    ),
                                })}
                                key={streamId}
                                style={{ width, height }}
                                onClick={() => {
                                    this.onSelect(streamId);
                                }}
                            >
                                <video
                                    ref={(ref) => {
                                        this.setVideoRef(streamId, ref);
                                    }}
                                    muted
                                />
                            </div>
                        );
                    })}
                </div>
                <div className="local-video">
                    <video ref={this.localVideoRef}></video>
                </div>
            </div>
        );
    }
}

Conference.propTypes = {
    layoutMode: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    focussedStreamIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    onStreamInfo: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onStreamClosed: PropTypes.func.isRequired,
};

export default Conference;
