import React, { createRef } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import './ShootHub.scss';
import WebRTCStream from '../components/Antmedia/WebRTCStream';
import StreamIdEditor from '../components/StreamIdEditor/StreamIdEditor';
import Conference from '../components/Conference/Conference';
import Fullscreen from '../components/Fullscreen/Fullscreen';
import Indicator from '../components/DualFeed/Indicator';

import Chat from '../components/Chat/Chat';
import DemoStreams from '../components/DemoStreams/DemoStreams';
import VideoChat from '../components/VideoChat/VideoChat';
import {
    DEFAULT_LOGO,
    DEMO_ASSET_BASE,
    SHOW_DEMO,
    API_HOST,
} from '../utils/constants';
import MenuToggle from '../components/Menu/MenuToggle';
import Menu from '../components/Menu/Menu';
import {
    setConfig,
    setConfigPass,
    setInitialConfigLoadCompleted,
} from '../redux/features/app';
import PasswordPrompt from '../components/PasswordPrompt/PasswordPrompt';

const AUTOHIDE_DELAY = 2500;

// View can be `one-primary` or `grid`

const demo_video = DEMO_ASSET_BASE + '3888252.mp4';

class ShootHub extends React.Component {
    constructor(props) {
        super(props);

        this.appWindowRef = createRef();
        this.mainVideoRef = createRef();
        this.secondaryVideoRef = createRef();

        this.state = {
            mainWidth: 1,
            mainHeight: 1,
            mainStreamId: '-',
            secondaryStreamId: '',
            streams: [],
            showStreamEditor: false,
            layoutMode: 'PRIMARY',
            thumbnailHeight: 200,
            global: {
                websocketURL: window.WEBSOCKET_URL,
                conferenceRoomName: window.CONFERENCE_ROOM_NAME,
            },
            shouldAutofocus: true,
            autoHideEnabled: true,
            hideUI: false,
            dataLoaded: false,
            dualSelecting: 'l',
            muted: false,
            roomId: '',
            chatEnabled: false,
            videochatEnabled: false,
        };

        this.streamInfo = {};

        this.hideUITimeout = null;
    }

    componentDidMount() {
        this.loadYamlConfig();
        this.calculateThumbnailsHeight();
        this.initMousemoveHandler();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.configPass === '' && this.props.configPass !== '') {
            this.loadYamlConfig();
        }
    }

    initResizeHandler = () => {
        window.addEventListener('resize', this.calculateThumbnailsHeight);
    };

    initMousemoveHandler = () => {
        window.addEventListener('mousemove', this.showUIonMouseMove);
        this.showUIonMouseMove();
    };

    showUIonMouseMove = () => {
        clearTimeout(this.hideUITimeout);
        this.hideUITimeout = setTimeout(this.hideUI, AUTOHIDE_DELAY);

        if (!this.state.hideUI) return;
        this.setState({
            hideUI: false,
        });
    };

    hideUI = () => {
        this.setState({ hideUI: true });
    };

    loadYamlConfig = async () => {
        console.log('load yaml');
        if (window.location.hash.length < 2) {
            return;
        }

        const hash = window.location.hash.replace('#', '');

        const timestamp = Date.now();

        try {
            let path =
                API_HOST +
                `/api/shoots/${hash}?key=${this.props.configPass}&tx=${timestamp}`;

            // TODO error handling
            const res = await fetch(path);
            const data = await res.json();

            if (res.status === 403) {
                this.props.dispatch(setConfigPass(''));
                this.props.dispatch(
                    setConfig({
                        logoUrl: data.logoUrl,
                    })
                );
                this.props.dispatch(setInitialConfigLoadCompleted(true));
                return;
            } else if (res.status !== 200) {
                console.error('res status', res.status, data);
                return;
            }

            // TODO: double check room exists or error somehow?
            const room = data.rooms[0];
            const streams = room.roomDevices;

            this.setState(
                {
                    dataLoaded: true,
                    streams,
                    mainStreamId: streams && streams[0].id,
                    global: {
                        websocketURL: data.websocketUrl,
                        conferenceRoomName: room.conferenceRoomId,
                        previewsBaseurl: '', // TODO: deprecate
                    },
                    roomId: hash,
                    chatEnabled: room.textChatEnabled === true,
                    videochatEnabled: room.videoChatEnabled === true,
                    logoUrl: data.logoUrl ? data.logoUrl : DEFAULT_LOGO,
                },
                () => {
                    this.calculateThumbnailsHeight();
                    this.initResizeHandler();
                }
            );

            this.props.dispatch(
                setConfig({
                    streams,
                    mainStreamId: streams && streams[0].id,
                    websocketURL: data.websocketUrl,

                    conferenceRoomName: room.conferenceRoomId,
                    previewsBaseurl: '', // TODO: deprecate
                    roomId: hash,
                    chatEnabled: room.textChatEnabled === true,
                    videochatEnabled: room.videoChatEnabled === true,
                    title: data.name,
                    logoUrl: data.logoUrl ? data.logoUrl : DEFAULT_LOGO,
                })
            );
        } catch (e) {
            console.error(e);
        }
    };

    onSelect = (streamId, src, subStreams) => {
        let ref = this.mainVideoRef;
        let side = 'main';
        if (
            this.state.layoutMode === 'DUAL_FEED' &&
            this.state.dualSelecting === 'r'
        ) {
            side = 'secondary';
            ref = this.secondaryVideoRef;
        }

        try {
            ref.current.srcObject = src;
            ref.current.play();
            const newState = {
                [`${side}StreamId`]: streamId,
                shouldAutofocus: false,
            };

            this.setState(newState);
        } catch (e) {
            console.log(e);
        }
    };

    onStreamInfo = (streamId, subStreams) => {
        this.streamInfo[streamId] = subStreams;
    };

    calculateThumbnailsHeight = () => {
        let width = 0;
        let height = 200;
        let margin = 20;

        this.state.streams.forEach((s) => {
            let streamWidth = 355;
            let streamHeight = 200;
            const id = s.id.toString();

            if (this.streamInfo[id]) {
                streamWidth = this.streamInfo[id][0].streamWidth;
                streamHeight = this.streamInfo[id][0].streamHeight;
            }
            width += margin + (height * streamWidth) / streamHeight;
        });

        if (width > window.innerWidth) {
            const scale = (window.innerWidth * 0.95) / width;
            height *= scale;
        }
        this.setState({ thumbnailHeight: height });
    };

    onSubmitStreamEditor = (streams) => {
        this.setState({ streams, showStreamEditor: false });
    };

    onOptionsStreamIdEditor = () => {
        this.setState({ showStreamEditor: true });
    };

    setLayoutMode = (mode) => {
        this.setState({ layoutMode: mode });
    };

    getGridWidth = (streamCount) => {
        if (streamCount < 2) {
            return 100;
        }
        if (streamCount < 5) {
            return 45;
        }
        if (streamCount < 9) {
            return 30;
        }
    };

    onConferenceStreamClosed = (streamId) => {
        delete this.streamInfo[streamId];
        this.calculateThumbnailsHeight();
    };

    onUpdateGlobalOptions = (options) => {
        this.setState({ options });
    };

    toggleAutoHideUIEnabled = () => {
        this.setState({
            autoHideEnabled: !this.state.autoHideEnabled,
        });
    };

    onStreamReconnected = (streamId) => {
        if (streamId === this.state.mainStreamId) {
        }
    };

    onSelectDualSide = (side) => {
        return () => {
            this.setState({
                dualSelecting: side,
            });
        };
    };

    renderPasswordPrompt() {
        return <PasswordPrompt />;
    }

    render() {
        if (!this.props.configPass && !SHOW_DEMO) {
            return this.renderPasswordPrompt();
        }

        const {
            mainStreamId,
            secondaryStreamId,
            streams,
            showStreamEditor,
            layoutMode,
            thumbnailHeight,
            global,
            shouldAutofocus,
            hideUI,
            autoHideEnabled,
            dualSelecting,
            muted,
            roomId,
            chatEnabled,
            videochatEnabled,
            logoUrl,
        } = this.state;

        const gridWidth = this.getGridWidth(streams.length);

        const focussedStreamIds = [
            mainStreamId,
            layoutMode === 'DUAL_FEED' ? secondaryStreamId : '',
        ];

        return (
            <div
                className={classnames('ShootHub', {
                    'hide-ui': autoHideEnabled && hideUI,
                    grid: layoutMode === 'GRID',
                    dualfeed: layoutMode === 'DUAL_FEED',
                    'chat-visible': this.props.chat.visible,
                    'videochat-visible': this.props.videochat.status === 'on',
                })}
                ref={this.appWindowRef}
            >
                <div className="logo">
                    <img src={logoUrl} alt="Shoot Logo" />
                </div>

                <div className="main-content-wrapper">
                    <div className="main-content">
                        <div className="loading-indicator">
                            Stream loading. Please wait...
                        </div>

                        <div
                            className="main-video"
                            style={{
                                width:
                                    layoutMode === 'DUAL_FEED' ? '50%' : '100%',
                                height: '100%',
                                visibility:
                                    layoutMode === 'GRID'
                                        ? 'hidden'
                                        : 'visible',
                            }}
                            onClick={this.onSelectDualSide('l')}
                        >
                            {SHOW_DEMO ? (
                                <video
                                    playsInline
                                    muted={muted}
                                    ref={this.mainVideoRef}
                                    src={demo_video}
                                    autoPlay
                                    loop
                                />
                            ) : (
                                <video
                                    playsInline
                                    muted={muted}
                                    ref={this.mainVideoRef}
                                />
                            )}
                        </div>

                        {layoutMode === 'DUAL_FEED' && (
                            <div
                                className="main-video"
                                style={{
                                    width: '50%',
                                    height: '100%',
                                }}
                                onClick={this.onSelectDualSide('r')}
                            >
                                <video
                                    playsInline
                                    muted={muted}
                                    ref={this.secondaryVideoRef}
                                />
                            </div>
                        )}

                        <div
                            className={classnames('video-thumbnails', {
                                grid: layoutMode === 'GRID',
                            })}
                        >
                            {SHOW_DEMO && <DemoStreams />}

                            {streams.map(({ id: streamId, name }, i) => (
                                <WebRTCStream
                                    focussed={
                                        focussedStreamIds.includes(streamId) &&
                                        layoutMode !== 'GRID'
                                    }
                                    streamId={streamId}
                                    layoutMode={layoutMode}
                                    width={gridWidth}
                                    height={thumbnailHeight}
                                    key={streamId}
                                    resolution={'240p'}
                                    onStreamInfo={(subStreams) => {
                                        this.onStreamInfo(streamId, subStreams);
                                    }}
                                    onSelect={(src, subStreams) => {
                                        this.onSelect(
                                            streamId,
                                            src,
                                            subStreams
                                        );
                                    }}
                                    autoFocus={shouldAutofocus && i === 0}
                                    name={name}
                                    previewsBaseurl={global.previewsBaseurl}
                                    websocketURL={global.websocketURL}
                                    onReconnected={() => {
                                        this.onStreamReconnected(streamId);
                                    }}
                                />
                            ))}

                            {global.websocketURL && (
                                <Conference
                                    layoutMode={layoutMode}
                                    width={gridWidth}
                                    height={thumbnailHeight}
                                    onStreamInfo={this.onStreamInfo}
                                    onSelect={this.onSelect}
                                    focussedStreamIds={focussedStreamIds}
                                    onStreamClosed={
                                        this.onConferenceStreamClosed
                                    }
                                    websocketURL={global.websocketURL}
                                    roomName={global.conferenceRoomName}
                                />
                            )}
                        </div>

                        <Fullscreen elemRef={this.appWindowRef} />

                        {/* 
                        <MuteToggle
                            isMuted={muted}
                            toggleCallback={() => {
                                this.setState({
                                    muted: !muted,
                                });
                            }}
                        />
                        */}

                        {layoutMode === 'DUAL_FEED' && (
                            <Indicator side={dualSelecting} />
                        )}

                        <MenuToggle />
                    </div>
                </div>

                {roomId && chatEnabled && (
                    <Chat key="chat_main" roomId={roomId} visible={true} />
                )}

                {showStreamEditor && (
                    <StreamIdEditor
                        streamIds={[]}
                        streams={streams}
                        onSubmit={this.onSubmitStreamIds}
                        options={global}
                        onUpdateOptions={this.onUpdateGlobalOptions}
                    />
                )}

                {videochatEnabled && <VideoChat roomname={roomId} />}

                <Menu
                    onStreamIdEditor={this.onOptionsStreamIdEditor}
                    setLayoutMode={this.setLayoutMode}
                    currentMode={layoutMode}
                    autoHideEnabled={autoHideEnabled}
                    toggleAutoHideUIEnabled={this.toggleAutoHideUIEnabled}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    chat: state.chat,
    videochat: state.videochat,
    configPass: state.app.configPass,
});

export default connect(mapStateToProps)(ShootHub);
