import { KeyboardEvent } from 'react';
import { useState } from 'react';

import Input from '../Form/Input';
import Button from '../Form/Button';

interface Props {
    onSubmit(name: string): void;
}

export default function ChatIntro({ onSubmit }: Props) {
    const [name, setName] = useState('');

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            onSubmit(name);
        }
    };

    return (
        <div className="ChatIntro">
            <div className="label">Please enter your name to get started.</div>
            <Input
                type="text"
                value={name}
                onChange={(e) => {
                    setName(e.target.value);
                }}
                onKeyDown={handleKeyDown}
                placeholder="Your Name"
            />
            <br />
            <br />
            <Button
                value="Connect to chat"
                onClick={() => {
                    onSubmit(name);
                }}
            />
        </div>
    );
}
