import { Provider } from 'react-redux';

import './index.scss';

import { store } from './redux/store';
import ShootHub from './pages/ShootHub.jsx';

export default function App() {
    return (
        <Provider store={store}>
            <ShootHub />
        </Provider>
    );
}
