import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CallItems } from '../../components/VideoChat/utils';
import { getInitialState } from '../store';

type VideoChatState_Status = 'off' | 'connecting' | 'on';
export interface VideochatState {
    callItems: CallItems;
    joined: boolean;
    status: VideoChatState_Status;
}

let initialState: VideochatState = getInitialState('videochat', {
    joined: false,
    callItems: {},
    status: 'off',
});

export const videochatSlice = createSlice({
    name: 'videochat',
    initialState,
    reducers: {
        setJoined: (state, action: PayloadAction<boolean>) => {
            state.joined = action.payload;
        },
        updateParticipants: (state, action: PayloadAction<CallItems>) => {
            state.callItems = action.payload;
        },
        setVideoChatStatus: (
            state,
            action: PayloadAction<VideoChatState_Status>
        ) => {
            state.status = action.payload;
        },
    },
});

export const { setJoined, updateParticipants, setVideoChatStatus } =
    videochatSlice.actions;

export default videochatSlice.reducer;
