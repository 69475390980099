import { useState } from 'react';

import { setName } from '../../redux/features/user';
import { useAppDispatch, useAppSelector } from '../../redux/store';

import Button from '../Form/Button';
import Input from '../Form/Input';

import './MenuIntro.scss';

export default function MenuIntro() {
    const dispatch = useAppDispatch();
    const { username } = useAppSelector((s) => ({
        username: s.user.name,
    }));

    const [newUsername, setNewUsername] = useState(username);

    // TODO: different depending on whether video and/or text is enabled?

    // TODO: better copy

    function handleSubmit() {
        dispatch(setName(newUsername));
    }

    return (
        <div className="MenuIntro">
            <div className="spacer"></div>
            <div className="content">
                <div className="intro-copy">
                    Welcome to the shoot. Enter your name to join the chat.
                </div>
                <div className="input-wrapper">
                    <Input
                        value={newUsername}
                        onChange={(e) => {
                            setNewUsername(e.target.value);
                        }}
                        placeholder="Your Name"
                        onEnter={handleSubmit}
                    />
                </div>

                <Button
                    value="Get Started"
                    disabled={!newUsername}
                    onClick={handleSubmit}
                />
            </div>
            <div className="spacer"></div>
        </div>
    );
}
