/* eslint-disable */

const TIMEOUT = 2500;
const RETRIES = 10;

export class WebSocketAdaptor {
    constructor(initialValues) {
        for (var key in initialValues) {
            if (initialValues.hasOwnProperty(key)) {
                this[key] = initialValues[key];
            }
        }
        this.connected = false;
        this.pingTimerId = null;

        initWebsocket(this.websocket_url, null, TIMEOUT, RETRIES)
            .then((ws) => {
                this.wsConn = ws;
                this.setupWebsocketHandlers(ws);

                this.pingTimerId = setInterval(() => {
                    this.sendPing();
                }, 3000);

                this.connected = true;
                this.callback('initialized');
            })
            .catch((e) => {
                this.callbackError('ws_connection_failed', {
                    retries: RETRIES,
                });
                console.log(
                    `Fail to connect to websocket after ${RETRIES} attempts`
                );
            });
    }

    setupWebsocketHandlers = (wsConn) => {
        wsConn.onmessage = (event) => {
            var obj = JSON.parse(event.data);

            if (obj.command == 'start') {
                //this command is received first, when publishing so playmode is false

                this.webrtcadaptor.startPublishing(obj.streamId);
            } else if (obj.command == 'takeCandidate') {
                if (this.debug) {
                    console.debug(
                        'received ice candidate for stream id ' + obj.streamId
                    );
                    console.debug(obj.candidate);
                }

                this.webrtcadaptor.takeCandidate(
                    obj.streamId,
                    obj.label,
                    obj.candidate
                );
            } else if (obj.command == 'takeConfiguration') {
                if (this.debug) {
                    console.log(
                        '++ received remote description type for stream id: ' +
                            obj.streamId +
                            ' type: ' +
                            obj.type
                    );
                }
                this.webrtcadaptor.takeConfiguration(
                    obj.streamId,
                    obj.sdp,
                    obj.type
                );
            } else if (obj.command == 'stop') {
                this.webrtcadaptor.closePeerConnection(obj.streamId);
            } else if (obj.command == 'error') {
                this.callbackError(obj.definition);
            } else if (obj.command == 'notification') {
                this.callback(obj.definition, obj);
                if (
                    obj.definition == 'play_finished' ||
                    obj.definition == 'publish_finished'
                ) {
                    this.webrtcadaptor.closePeerConnection(obj.streamId);
                }
            } else if (obj.command == 'streamInformation') {
                this.callback(obj.command, obj);
            } else if (obj.command == 'roomInformation') {
                this.callback(obj.command, obj);
            } else if (obj.command == 'pong') {
                this.callback(obj.command);
            } else if (obj.command == 'trackList') {
                this.callback(obj.command, obj);
            } else if (obj.command == 'connectWithNewId') {
                this.multiPeerStreamId = obj.streamId;
                this.join(obj.streamId);
            } else if (obj.command == 'peerMessageCommand') {
                this.callback(obj.command, obj);
            }
        };

        wsConn.onerror = (error) => {
            console.log(' error occured: ' + JSON.stringify(error));
            this.clearPingTimer();
            this.callbackError('wserror', error);
        };

        wsConn.onclose = (event) => {
            this.connected = false;
            // console.log('connection closed.');
            this.clearPingTimer();
            this.callback('closed', event);
        };
    };

    clearPingTimer() {
        if (this.pingTimerId) {
            if (this.debug) {
                console.debug('Clearing ping message timer');
            }
            clearInterval(this.pingTimerId);
            this.pingTimerId = null;
        }
    }

    sendPing() {
        var jsCmd = {
            command: 'ping',
        };
        this.wsConn.send(JSON.stringify(jsCmd));
    }

    close() {
        this.wsConn.close();
    }

    send(text) {
        if (
            this.wsConn.readyState == 0 ||
            this.wsConn.readyState == 2 ||
            this.wsConn.readyState == 3
        ) {
            this.callbackError('WebSocketNotConnected');
            return;
        }
        this.wsConn.send(text);
    }

    isConnected() {
        return this.connected;
    }
}

// https://stackoverflow.com/questions/29881957/websocket-connection-timeout
/**
 * inits a websocket by a given url, returned promise resolves with initialized websocket, rejects after failure/timeout.
 *
 * @param url the websocket url to init
 * @param existingWebsocket if passed and this passed websocket is already open, this existingWebsocket is resolved, no additional websocket is opened
 * @param timeoutMs the timeout in milliseconds for opening the websocket
 * @param numberOfRetries the number of times initializing the socket should be retried, if not specified or 0, no retries are made
 *        and a failure/timeout causes rejection of the returned promise
 * @return {Promise}
 */
function initWebsocket(url, existingWebsocket, timeoutMs, numberOfRetries) {
    timeoutMs = timeoutMs ? timeoutMs : 1500;
    numberOfRetries = numberOfRetries ? numberOfRetries : 0;
    var hasReturned = false;
    let websocket;
    var promise = new Promise((resolve, reject) => {
        setTimeout(function () {
            if (!hasReturned) {
                rejectInternal();
            }
        }, timeoutMs);
        if (
            !existingWebsocket ||
            existingWebsocket.readyState != existingWebsocket.OPEN
        ) {
            if (existingWebsocket) {
                existingWebsocket.close();
            }

            websocket = new WebSocket(url);

            websocket.onopen = function () {
                if (hasReturned) {
                    websocket.close();
                } else {
                    resolve(websocket);
                }
            };
            websocket.onclose = function () {
                rejectInternal();
            };
            websocket.onerror = function (e) {
                rejectInternal();
            };
        } else {
            resolve(existingWebsocket);
        }

        function rejectInternal() {
            try {
                websocket.close();
            } catch (e) {
                // console.log('cant close ws', e);
            }

            if (numberOfRetries <= 0) {
                reject();
            } else if (!hasReturned) {
                hasReturned = true;
                initWebsocket(url, null, timeoutMs, numberOfRetries - 1).then(
                    resolve,
                    reject
                );
            }
        }
    });
    promise.then(
        function () {
            hasReturned = true;
        },
        function () {
            hasReturned = true;
        }
    );
    return promise;
}
