import Daily, {
    DailyEvent,
    DailyEventObject,
    DailyParticipant,
} from '@daily-co/daily-js';
import { DailyCall } from '@daily-co/daily-js';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import './VideoChat.scss';

import Tile from './Tile/Tile';
import { RootState } from '../../redux/store';
import {
    setJoined,
    setVideoChatStatus,
    VideochatState,
} from '../../redux/features/videochat';
import Icon from '../Icon/Icon';
import { DEMO_ASSET_BASE, SHOW_DEMO } from '../../utils/constants';
import { toggleVisibility } from '../../redux/features/chat';

interface Props {
    videochat: VideochatState;
    dispatch: Function;
    username: string;
    roomname: string;
    chatVisible: boolean;
}
interface State {
    participants: ParticipantsObject;
    streams: {
        isCameraMuted: boolean;
        isMicMuted: boolean;
        isSharingScreen: boolean;
    };
    devices: MediaDeviceInfo[];
}

const events = ['joined-meeting', 'left-meeting', 'error'];
const participantEvents = [
    'participant-joined',
    'participant-updated',
    'participant-left',
];

export interface ParticipantsObject {
    [id: string]: DailyParticipant;
}

class VideoChat extends Component<Props, State> {
    dailyCall: DailyCall;
    iframeRef: React.RefObject<HTMLIFrameElement>;

    // TODO: unused?
    joined: boolean = false;

    constructor(props: Props) {
        super(props);

        this.iframeRef = React.createRef<HTMLIFrameElement>();
        this.dailyCall = Daily.createCallObject({});

        this.state = {
            participants: {},
            devices: [],
            streams: {
                isCameraMuted: false,
                isMicMuted: false,
                isSharingScreen: false,
            },
        };
    }

    async componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            prevProps.videochat.joined === false &&
            this.props.videochat.joined === true
        ) {
            this.join();
        }

        if (
            prevProps.videochat.joined === true &&
            this.props.videochat.joined === false
        ) {
            await this.leave();
        }
    }

    join = async () => {
        if (this.props.videochat.status !== 'off') return;
        this.props.dispatch(setVideoChatStatus('connecting'));

        // Limit to 33 chars from daily.co
        const roomname = this.props.roomname
            ? this.props.roomname.slice(0, 33)
            : '';

        await this.dailyCall.join({
            url: 'https://shoothub.daily.co/' + roomname,
        });
        await this.dailyCall.setUserName(this.props.username);

        // Start webcams
        // this.dailyCall.setLocalVideo(true);
        // this.dailyCall.setLocalAudio(true);

        for (const event of participantEvents) {
            this.dailyCall.on(
                event as DailyEvent,
                this.handleNewParticipantsState
            );
        }

        this.handleNewParticipantsState();
        this.updateDevices();

        this.props.dispatch(setVideoChatStatus('on'));
    };

    updateDevices = async () => {
        const { devices } = await this.dailyCall.enumerateDevices();
        this.setState({
            devices,
        });
    };

    setVideoDevice = async (id: string) => {
        await this.dailyCall.setInputDevicesAsync({ videoDeviceId: id });
    };

    setAudioDevice = async (id: string) => {
        await this.dailyCall.setInputDevicesAsync({ audioDeviceId: id });
    };

    leave = async () => {
        for (const event of participantEvents) {
            this.dailyCall.off(
                event as DailyEvent,
                this.handleNewParticipantsState
            );
        }
        await this.dailyCall.leave();
        this.setState({
            participants: {},
        });
        this.props.dispatch(setVideoChatStatus('off'));
        this.props.dispatch(setJoined(false));
        this.joined = false;
    };

    getStreamStates(callObject: DailyCall) {
        let isCameraMuted = false,
            isMicMuted = false,
            isSharingScreen = false;
        if (
            callObject &&
            callObject.participants() &&
            callObject.participants().local
        ) {
            const localParticipant = callObject.participants().local;
            isCameraMuted = !localParticipant.video;
            isMicMuted = !localParticipant.audio;
            isSharingScreen = localParticipant.screen;
        }
        return { isCameraMuted, isMicMuted, isSharingScreen };
    }

    handleNewParticipantsState = (e?: DailyEventObject) => {
        console.log('handleNewParticipantsState', e);
        const participants = this.dailyCall.participants();
        console.log(participants);
        this.setState({
            streams: this.getStreamStates(this.dailyCall),
            participants,
        });
        // this.props.dispatch(
        //     updateParticipants(getCallItems(this.dailyCall.participants()))
        // );
    };

    renderTiles = () => {
        return (
            <>
                {Object.entries(this.state.participants).map(
                    ([id, participant]) => {
                        return (
                            <Tile participant={participant} key={id} id={id} />
                        );
                    }
                )}
                {this.renderDemoTiles()}
            </>
        );
    };

    renderDemoTiles = () => {
        if (!SHOW_DEMO) return null;

        const videos = [
            {
                v: '8762889_1.mp4',
                n: 'Max',
            },
            {
                v: '8545627_1.mp4',
                n: 'Peggy',
            },
            {
                v: '8555659_1.mp4',
                n: 'Studio B',
            },
        ];

        return videos.map((d, i) => {
            return (
                <div
                    className="VideoChat__Tile video_playable"
                    key={d.v + i.toString()}
                >
                    <video src={DEMO_ASSET_BASE + d.v} autoPlay muted />
                    <div className="name">{d.n}</div>
                </div>
            );
        });
    };

    toggleVideo = () => {
        this.dailyCall.setLocalVideo(this.state.streams.isCameraMuted);
    };
    toggleAudio = () => {
        this.dailyCall.setLocalAudio(this.state.streams.isMicMuted);
    };

    renderActiveTwo = () => {
        const { chatVisible } = this.props;

        return (
            <div className={classNames(`VideoChat`, { chatVisible })}>
                <div className="VideoChat_Main">
                    <div className="menu">
                        <div>
                            <Icon
                                size={34}
                                type={
                                    this.state.streams.isCameraMuted
                                        ? 'camera-off'
                                        : 'camera'
                                }
                                onClick={this.toggleVideo}
                            />
                        </div>
                        <div>
                            <Icon
                                size={34}
                                type={
                                    this.state.streams.isMicMuted
                                        ? 'mic-off'
                                        : 'mic'
                                }
                                onClick={this.toggleAudio}
                            />
                        </div>
                        <div>
                            <Icon
                                size={34}
                                type="chat"
                                onClick={() => {
                                    this.props.dispatch(toggleVisibility());
                                }}
                            />
                        </div>
                        <div>
                            <Icon size={34} type="exit" onClick={this.leave} />
                        </div>
                    </div>
                    <div className="spacer"></div>
                    <div className="participants">{this.renderTiles()}</div>
                </div>
            </div>
        );
    };

    renderActive = () => {
        return (
            <>
                <Icon
                    type={
                        this.state.streams.isCameraMuted
                            ? 'camera-off'
                            : 'camera'
                    }
                    onClick={this.toggleVideo}
                />
                <Icon
                    type={this.state.streams.isMicMuted ? 'mic-off' : 'mic'}
                    onClick={this.toggleAudio}
                />
                <Icon type="exit" onClick={this.leave} />

                <select
                    onChange={(e) => {
                        this.setVideoDevice(e.target.value);
                    }}
                >
                    {this.state.devices
                        .filter((d) => d.kind === 'videoinput')
                        .map((d) => (
                            <option key={d.deviceId} value={d.deviceId}>
                                {d.label}
                            </option>
                        ))}
                </select>
                <select
                    onChange={(e) => {
                        this.setAudioDevice(e.target.value);
                    }}
                >
                    {this.state.devices
                        .filter((d) => d.kind === 'audioinput')
                        .map((d) => (
                            <option key={d.deviceId} value={d.deviceId}>
                                {d.label}
                            </option>
                        ))}
                </select>
                <br />
                {this.renderTiles()}
            </>
        );
    };

    renderConnecting = () => {
        return <Icon type="loading" />;
    };

    render() {
        return (
            <>
                {(() => {
                    switch (this.props.videochat.status) {
                        case 'on':
                            return this.renderActiveTwo();
                        case 'connecting':
                            return this.renderConnecting();
                        default:
                            return null;
                    }
                })()}
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    username: state.user.name,
    videochat: state.videochat,
    chatVisible: state.chat.visible,
});

export default connect(mapStateToProps)(VideoChat);
