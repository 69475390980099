import classNames from 'classnames';
import './Button.scss';

type Button_Style = 'light' | 'dark';

interface Props {
    onClick: () => void;
    value: string;
    styleName?: Button_Style;
    disabled?: boolean;
}

export default function Button({
    onClick,
    value,
    styleName = 'light',
    disabled = false,
}: Props) {
    return (
        <div
            className={classNames('form__button', { disabled }, styleName)}
            onClick={disabled ? () => {} : onClick}
        >
            {value}
        </div>
    );
}
