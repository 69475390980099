import React, { KeyboardEvent } from 'react';

import './Input.scss';

type Props = {
    colorStyle?: 'dark' | 'light';
    inputType?: string;
    onEnter?: (value: string) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

export default function Input({
    className,
    onEnter,
    colorStyle = 'light',
    ...props
}: Props) {
    function internalOnEnter(e: KeyboardEvent<HTMLInputElement>) {
        const value = e.currentTarget.value;
        if (e.key === 'Enter' && onEnter) {
            onEnter(value);
        }
        if (props.onKeyDown) props.onKeyDown(e);
    }

    return (
        <input
            className={`form__input ${colorStyle} ` + className}
            {...props}
            onKeyDown={internalOnEnter}
        />
    );
}
