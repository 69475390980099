import {
    createSlice,
    //    PayloadAction
} from '@reduxjs/toolkit';

export interface ShootState {
    title: string;
    id: string;
    roomId: string;
}

const initialState: ShootState = {
    title: 'My Shoot',
    id: 'shoot-abcd',
    roomId: 'my-shoot-room',
};

export const shootSlice = createSlice({
    name: 'shoot',
    initialState,
    reducers: {},
});

export const {} = shootSlice.actions;
export default shootSlice.reducer;
