import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getInitialState } from '../store';

export interface ChatState {
    visible: boolean;
    connected: boolean;
    lastMessageReadId: string;
    messages?: Message[];
}

let initialState: ChatState = getInitialState('chat', {
    visible: false,
    connected: false,
    lastMessageReadId: 'abcd',
    messages: [],
});

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        toggleVisibility: (state) => {
            state.visible = !state.visible;
            if (state.messages && state.messages.length > 0) {
                state.lastMessageReadId =
                    state.messages[state.messages.length - 1].id;
            }
        },
        setConnection: (state, action: PayloadAction<boolean>) => {
            state.connected = action.payload;
        },
        setLastMessageIdRead: (state, action: PayloadAction<string>) => {
            state.lastMessageReadId = action.payload;
        },
        addMessage: (state, action: PayloadAction<Message>) => {
            // TODO: check duplicates?
            if (state.messages) {
                state.messages.push(action.payload);
            } else {
                state.messages = [action.payload];
            }
            if (state.visible) {
                state.lastMessageReadId = action.payload.id;
            }
        },
    },
});

export const {
    toggleVisibility,
    setConnection,
    setLastMessageIdRead,
    addMessage,
} = chatSlice.actions;

export default chatSlice.reducer;

export interface Message {
    id: string;
    timestamp: string;
    senderName: string;
    message: string;
}
