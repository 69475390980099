import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './WebRTCStream.scss';

import { initialise } from '../../utils/webrtc';
import throttle from '../../utils/throttle';

const USE_THUMBNAILS = false;

class WebRTCStream extends React.Component {
    static defaultProps = {
        fillMode: 'cover',
    };

    constructor(props) {
        super(props);

        this.videoElem = null;
        this.adaptor = null;
        this.videoStreamSource = null;
        this.autoFocussed = false;

        this.wrapperRef = React.createRef();

        this.state = {
            status: 'loading',
            isLoading: true,
            streams: [],
            streamIndex: 0,
            size: null,
            forceUpdate: 0,
            previewImageTimestamp: Date.now(),
            reconnecting: false,
        };
    }

    componentDidMount() {
        this.initAdaptor();

        window.addEventListener('resize', throttle(this.forceUpdate, 100));

        if (USE_THUMBNAILS && !this.props.focussed) {
            this.nextPreviewImage();
        }
    }

    initAdaptor = () => {
        this.adaptor = initialise({
            streamId: this.props.streamId,
            videoElement: this.videoElem,
            websocketURL: this.props.websocketURL,
            callback: {
                onPlayStarted: this.onPlayStarted,
                onPlayFinished: this.onPlayFinished,
                onStreamInfo: this.onStreamInfo,
                onVideoStreamSource: this.onVideoStreamSource,
                onClose: this.reconnect,
                onError: this.reconnect,
            },
        });
    };

    reconnect = () => {
        this.setState({
            reconnecting: true,
        });
        this.initAdaptor();
    };

    componentDidUpdate(prevProps) {
        if (
            this.props.layoutMode === 'GRID' &&
            prevProps.layoutMode !== 'GRID'
        ) {
            this.forceUpdate();
        }

        if (this.props.focussed && !prevProps.focussed) {
            const { streams } = this.state;
            const targetIndex = streams.length - 1;
            if (streams && streams[targetIndex]) {
                let size = streams[targetIndex].streamHeight;
                this.forceStreamQuality(size, targetIndex);
            }
        }

        if (USE_THUMBNAILS && !this.props.focussed && prevProps.focussed) {
            this.adaptor.stop(this.props.streamId);
            this.nextPreviewImage();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.autofocusTimeout);
        clearTimeout(this.previewImageTimeout);
        this.adaptor.stop(this.props.streamId);
    }

    forceUpdate = () => {
        this.setState({ forceUpdate: this.state.forceUpdate + 1 });
    };

    setVideoElem = (element) => {
        this.videoElem = element;
    };

    onPlayStarted = () => {
        this.setState({ isLoading: false, status: 'playing', streamIndex: 0 });
    };

    onPlayFinished = () => {
        this.setState({ isLoading: true, streams: [] });
    };

    onStreamInfo = (streams) => {
        const filtered = streams.filter((s) => s.videoBitrate);
        this.setState({ streams: filtered, reconnecting: false }, () => {
            this.props.onStreamInfo(filtered);
            this.adaptor.play(this.props.streamId);
        });
    };

    forceStreamQuality = (size, streamIndex) => {
        this.adaptor.forceStreamQuality(this.props.streamId, size);
        this.setState({ size, streamIndex });
    };

    onVideoStreamSource = (source) => {
        this.videoStreamSource = source;

        if (this.props.autoFocus) {
            this.autofocusTimeout = setTimeout(() => {
                this.props.onSelect(this.videoStreamSource, this.state.streams);
            }, 2000);
        } else if (this.props.focussed) {
            this.props.onSelect(this.videoStreamSource, this.state.streams);
        }
    };

    onSelect = () => {
        if (USE_THUMBNAILS) {
            this.stopPreviewImage();
            this.adaptor.play(this.props.streamId);
        }
        this.props.onSelect(this.videoStreamSource, this.state.streams);
    };

    setSize = (size, streamIndex) => {
        return (e) => {
            e.stopPropagation();
            this.forceStreamQuality(size, streamIndex);
        };
    };

    previewImageUrl = (timestamp) => {
        const { streamId, previewsBaseurl, focussed } = this.props;
        const { previewImageTimestamp } = this.state;
        if (focussed) {
            return '';
        }
        return (
            previewsBaseurl +
            streamId +
            '.png?cache=' +
            (timestamp || previewImageTimestamp)
        );
    };

    stopPreviewImage = () => {
        clearTimeout(this.previewImageTimeout);
    };

    nextPreviewImage = () => {
        const nextImageTimestamp = Date.now();
        const url = this.previewImageUrl(nextImageTimestamp);

        this.nextImg = new Image();
        this.nextImg.src = url;
        this.nextImg.onload = () => {
            this.setState({
                previewImageTimestamp: nextImageTimestamp,
            });
            if (!this.props.focussed) {
                this.previewImageTimeout = setTimeout(
                    this.nextPreviewImage,
                    5000
                );
            }
        };
    };

    render() {
        const {
            layoutMode,
            width: propWidth,
            height: propHeight,
            name,
        } = this.props;
        const { streams, streamIndex, status } = this.state;

        let height = propHeight;
        let width = (propHeight * 4) / 3;

        let videoWrapperHeight, videoWrapperWidth;

        const stream = streams[streamIndex];
        if (stream) {
            width = (height * stream.streamWidth) / stream.streamHeight;
        }

        if (layoutMode === 'GRID') {
            width = propWidth + '%';
            height = 'auto';

            if (stream && this.wrapperRef.current) {
                const rect = this.wrapperRef.current.getBoundingClientRect();
                if (
                    stream.streamWidth / stream.streamHeight >
                    rect.width / rect.height
                ) {
                    videoWrapperWidth = rect.width;
                    videoWrapperHeight =
                        (rect.width * stream.streamHeight) / stream.streamWidth;
                } else {
                    videoWrapperHeight = rect.height;
                    videoWrapperWidth =
                        (rect.height * stream.streamWidth) /
                        stream.streamHeight;
                }
            }
        }

        return (
            <div
                className={classnames('WebRTCStream', status, {
                    focussed: this.props.focussed,
                })}
                style={{ width, height }}
                onClick={this.onSelect.bind(this)}
                ref={this.wrapperRef}
            >
                <div
                    className="webrtc-wrapper"
                    style={{
                        width: videoWrapperWidth,
                        height: videoWrapperHeight,
                    }}
                >
                    <video ref={this.setVideoElem} playsInline muted></video>

                    <div className="controls">
                        <div className="sizes">
                            {streams.map((s, i) => (
                                <div
                                    className={classnames('size', {
                                        current: i === streamIndex,
                                    })}
                                    onClick={this.setSize(s.streamHeight, i)}
                                    key={s.streamHeight + 'p'}
                                >
                                    {s.streamHeight}p
                                </div>
                            ))}
                        </div>
                        {USE_THUMBNAILS && (
                            <div
                                className="preview"
                                style={{
                                    backgroundImage: `url(${this.previewImageUrl()})`,
                                }}
                            ></div>
                        )}

                        <div className="loading-copy">
                            Stream loading. Please wait...
                        </div>

                        <div className="name">{name}</div>
                    </div>
                </div>
            </div>
        );
    }
}

WebRTCStream.propTypes = {
    focussed: PropTypes.bool,
    streamId: PropTypes.string.isRequired,
    resolution: PropTypes.string.isRequired,
    layoutMode: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    onStreamInfo: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    fillMode: PropTypes.string,
    autoFocus: PropTypes.bool,
};

export default WebRTCStream;
