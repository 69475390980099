import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';

import firebase from 'firebase/app';
import 'firebase/firestore';
import App from './App';

var firebaseConfig = {
    apiKey: 'AIzaSyDl9xAZelLE4gJJzKUyrmXfqAjgiKwTt3s',
    authDomain: 'shoot-hub.firebaseapp.com',
    projectId: 'shoot-hub',
    storageBucket: 'shoot-hub.appspot.com',
    messagingSenderId: '38861606039',
    appId: '1:38861606039:web:ba898090014d8c5f162dd2',
};
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
