import DailyIframe, {
    DailyParticipant,
    DailyParticipantsObject,
    DailyParticipantStreamCss,
    DailyTrackState,
} from '@daily-co/daily-js';

export interface CallItem {
    videoTrackState: DailyTrackState;
    audioTrackState: DailyTrackState;
}

export interface CallItems {
    [key: string]: CallItem;
}

export function shouldIncludeScreenCallItem(participant: DailyParticipant) {
    const trackStatesForInclusion = ['loading', 'playable', 'interrupted'];
    return (
        trackStatesForInclusion.includes(
            participant.tracks.screenVideo.state
        ) ||
        trackStatesForInclusion.includes(participant.tracks.screenAudio.state)
    );
}

export function getCallItems(participants: DailyParticipantsObject): CallItems {
    const callItems: CallItems = {};

    for (const [id, participant] of Object.entries(participants)) {
        callItems[id] = {
            videoTrackState: participant.tracks.video,
            audioTrackState: participant.tracks.audio,
        };
        if (shouldIncludeScreenCallItem(participant)) {
            callItems[id + '-screen'] = {
                videoTrackState: participant.tracks.screenVideo,
                audioTrackState: participant.tracks.screenAudio,
            };
        }
    }
    return callItems;
}

export function isLocal(id: string) {
    return id === 'local';
}

export function isScreenShare(id: string) {
    return id.endsWith('-screen');
}

export function containsScreenShare(callItems: CallItem) {
    return Object.keys(callItems).some((id) => isScreenShare(id));
}
