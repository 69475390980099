import React from 'react';
import { useState } from 'react';

import sendIcon from '../../assets/img/send.svg';
import escapeHtml from '../../utils/escapeHtml';

interface Props {
    onMessageSubmit: (m: string) => void;
}

let ignoreOnchange = false;

export default function ChatEntry({ onMessageSubmit }: Props) {
    const [message, setMessage] = useState('');

    let sanitisedMessage = escapeHtml(message).replaceAll('\n', '<br>');
    sanitisedMessage += '<br>';

    function submit() {
        onMessageSubmit(message);
        setMessage('');
    }

    function onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        // Check to see if the last character that was added was a return and ignore if that is the case. We will handle this in the keydown handler.
        if (ignoreOnchange) {
            ignoreOnchange = false;
            return;
        }

        setMessage(e.target.value);
    }

    function onKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (e.key === 'Enter' && !e.shiftKey) {
            ignoreOnchange = true;
            submit();
        }
    }

    return (
        <div className="ChatEntry">
            <div className="left">
                <div className="wrapper">
                    <div
                        className="auto-spacer"
                        dangerouslySetInnerHTML={{ __html: sanitisedMessage }}
                    ></div>
                    <textarea
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        value={message}
                    ></textarea>
                </div>
            </div>
            <div className="right">
                <img src={sendIcon} alt="Send Message" onClick={submit} />
            </div>
        </div>
    );
}
