import escapeHtml from '../../utils/escapeHtml';

interface Props {
    id: string;
    timestamp: string;
    senderName: string;
    message: string;
    fromSelf: boolean;
}

export default function ChatMessage(props: Props) {
    let date: Date | null = null;
    try {
        date = new Date(props.timestamp);
    } catch (e) {
        if (e) console.error(e);
    }

    const sanitisedMessage = escapeHtml(props.message).replaceAll('\n', '<br>');

    return (
        <div className={`ChatMessage ${props.fromSelf ? 'fromSelf' : ''}`}>
            <div className="icon"></div>
            <div className="body">
                <div className="message">
                    <div className="message-info">
                        <div className="name" title={props.senderName}>
                            {props.senderName +
                                `${props.fromSelf ? ' (you)' : ''}`}
                        </div>
                        <div
                            className="time"
                            title={date ? date.toISOString() : ''}
                        >
                            {date && date.getHours()}:
                            {date &&
                                date.getMinutes().toString().padStart(2, '0')}
                        </div>
                    </div>
                    <span
                        dangerouslySetInnerHTML={{ __html: sanitisedMessage }}
                    ></span>
                </div>
            </div>
            <div className="spacer"></div>
        </div>
    );
}
