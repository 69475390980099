import React, { useState } from 'react';

import './StreamIdEditor.scss';

export default function StreamIdEditor(props) {
    const [newStreams, setNewStreams] = useState(props.streams);
    const [options, setOptions] = useState(props.options);

    return (
        <div className="StreamIdEditor">
            <div className="content-wrapper">
                Stream ID Editor
                <div className="streamIds">
                    {newStreams &&
                        newStreams.map(({ id }, i) => (
                            <div key={i}>
                                <input
                                    type="text"
                                    value={id}
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        const updatedIds = [...newStreams];
                                        updatedIds[i].id = e.target.value;
                                        setNewStreams(updatedIds);
                                    }}
                                />
                            </div>
                        ))}
                </div>
                <button
                    onClick={() => {
                        props.onSubmit(newStreams);
                    }}
                >
                    Update
                </button>
                <div className="options">
                    <div>
                        <label>Conference Room Name</label>{' '}
                        <input
                            type="text"
                            value={options.conferenceRoomName}
                            onChange={(e) => {
                                setOptions({
                                    ...options,
                                    conferenceRoomName: e.target.value,
                                });
                            }}
                        />
                    </div>
                    <div>
                        <label>Websocket URL</label>{' '}
                        <input
                            type="text"
                            value={options.websocketURL}
                            onChange={(e) => {
                                setOptions({
                                    ...options,
                                    websocketURL: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
