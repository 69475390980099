import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getInitialState } from '../store';

export interface UserState {
    name: string;
    id: string;
}

let initialState: UserState = getInitialState('user', {
    name: '',
    id: '',
});

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setName: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
    },
});

export const { setName } = userSlice.actions;
export default userSlice.reducer;
