import Icon from '../Icon/Icon';
import { toggleMenu } from '../../redux/features/app';
import { useAppDispatch, useAppSelector } from '../../redux/store';

export default function MenuToggle() {
    const dispatch = useAppDispatch();

    const {
        lastReadMessageId,
        lastMessageId,
        videoConferenceLoading,
    } = useAppSelector((state) => {
        return {
            lastReadMessageId: state.chat.lastMessageReadId,
            lastMessageId:
                state.chat.messages && state.chat.messages.length
                    ? state.chat.messages[state.chat.messages.length - 1].id
                    : '',
            videoConferenceLoading: state.videochat.status === 'connecting',
        };
    });

    const hasUnread = lastMessageId && lastReadMessageId !== lastMessageId;

    return (
        <div
            className="MenuToggle"
            onClick={() => {
                dispatch(toggleMenu());
            }}
        >
            <Icon size={40} type="dots-three" />
            <span className="label">Menu</span>
            {hasUnread && <div className="unreadIndicator"></div>}
            {videoConferenceLoading && <Icon size={40} type="loading" />}
        </div>
    );
}
