import classnames from 'classnames';
import { useState } from 'react';
import { DEMO_ASSET_BASE } from '../../utils/constants';

export default function DemoStreams() {
    const [selected, setSelected] = useState('3888252.mp4');

    const videos = [
        {
            src: '6988770.mp4',
            width: 113,
            height: 200,
        },
        {
            src: '8485166.mp4',
            width: 356,
            height: 200,
        },
        {
            src: '3888252.mp4',
            width: 379,
            height: 200,
        },
    ];

    return videos.map((v) => {
        const { width, height } = v;
        return (
            <div
                className={classnames('WebRTCStream', {
                    focussed: v.src === selected,
                })}
                key={v.src}
                style={{ width, height }}
                onClick={() => {
                    (
                        document.querySelector(
                            '.main-video video'
                        ) as HTMLVideoElement
                    ).src = DEMO_ASSET_BASE + v.src;
                    setSelected(v.src);
                }}
            >
                <div className="webrtc-wrapper" style={{ width, height }}>
                    <video
                        src={DEMO_ASSET_BASE + v.src}
                        playsInline
                        muted
                        autoPlay
                        loop
                    />
                </div>
            </div>
        );
    });
}
