import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import throttle from '../utils/throttle';

import userReducer, { UserState } from './features/user';
import chatReducer, { ChatState } from './features/chat';
import shootReducer, { ShootState } from './features/shoot';
import videochatReducer, { VideochatState } from './features/videochat';
import appReducer, { AppState } from './features/app';

// const localStorageKey = 'shootHubData';

export const store = configureStore({
    reducer: {
        user: userReducer,
        chat: chatReducer,
        shoot: shootReducer,
        videochat: videochatReducer,
        app: appReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// helper function to get the initial value from local storage
export function getInitialState(
    reducerName: 'user' | 'chat' | 'shoot' | 'videochat' | 'app',
    initial: UserState | ChatState | ShootState | VideochatState | AppState
): any {
    const stateFromLocalstorage = window.localStorage.getItem('shootHubData');
    if (stateFromLocalstorage) {
        let data: RootState | null = null;
        try {
            data = JSON.parse(stateFromLocalstorage) as RootState;

            if (data && data[reducerName]) {
                const sliceData = data[reducerName];
                return {
                    ...initial,
                    ...sliceData,
                };
            }
        } catch (error) {
            console.error(error);
        }
    }
    return initial;
}

store.subscribe(
    throttle(() => {
        const data = store.getState();
        const forStorage = {
            user: { ...data.user },
            chat: {
                lastMessageReadId: data.chat.lastMessageReadId,
            },
            shoot: {},

            app: { configPass: data.app.configPass },
        };
        localStorage.setItem('shootHubData', JSON.stringify(forStorage));
    }, 1000)
);
