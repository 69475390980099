import classNames from 'classnames';
import { MouseEventHandler } from 'react';

import './Icon.scss';

import camera from '../../assets/img/VideoCamera.svg';
import cameraOff from '../../assets/img/VideoCameraSlash.svg';
import mic from '../../assets/img/Microphone.svg';
import micOff from '../../assets/img/MicrophoneSlash.svg';
import exit from '../../assets/img/SignOut.svg';
import spinner from '../../assets/img/Spinner.svg';
import dotsThree from '../../assets/img/DotsThreeOutline.svg';
import minimise from '../../assets/img/minimise.svg';
import x from '../../assets/img/x.svg';
import chat from '../../assets/img/ChatIcon.svg';

export const icontypes = [
    'camera',
    'camera-off',
    'mic',
    'mic-off',
    'exit',
    'loading',
    'dots-three',
    'minimise',
    'x',
    'chat',
] as const;
export type IconType = typeof icontypes[number];

interface Props {
    type: IconType;
    colorScheme?: 'dark' | 'light' | 'red';
    onClick?: MouseEventHandler<HTMLElement>;
    size?: Number;
    className?: string;
}

const ICONS = {
    camera: camera,
    'camera-off': cameraOff,
    mic: mic,
    'mic-off': micOff,
    exit,
    loading: spinner,
    'dots-three': dotsThree,
    minimise,
    x,
    chat,
};

export default function Icon({
    type,
    colorScheme = 'dark',
    onClick,
    size = 30,
    className = '',
}: Props) {
    return (
        <div
            className={classNames(
                `Icon Icon_${type} Icon_${colorScheme}`,
                className
            )}
            onClick={onClick}
            style={{ width: `${size}px`, height: `${size}px` }}
        >
            <img src={ICONS[type]} alt="" />
        </div>
    );
}
