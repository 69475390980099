export const SHOW_DEMO = window.location.hash === '#sample' ? true : false;
export const DEMO_ASSET_BASE =
    window.location.hostname === 'localhost'
        ? '/samples/'
        : 'https://shoothub-demo.s3.eu-west-2.amazonaws.com/demo-videos/';

export const DEFAULT_LOGO = '/logos/shoot-hub-white.svg';

const envApiHost = process.env.REACT_APP_API_HOST
    ? process.env.REACT_APP_API_HOST
    : '';

export const API_HOST =
    window.location.hostname === 'localhost'
        ? 'http://localhost:3002'
        : envApiHost;
