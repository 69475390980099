import classNames from 'classnames';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/store';
import { toggleMenu } from '../../redux/features/app';
import { setJoined } from '../../redux/features/videochat';
import { toggleVisibility } from '../../redux/features/chat';

import Input from '../Form/Input';
import Button from '../Form/Button';
import MenuIntro from './MenuIntro';

import './Menu.scss';
import { useState } from 'react';
import { setName } from '../../redux/features/user';
import Icon from '../Icon/Icon';
import { useCallback } from 'react';

const DISPLAY_MODES = [
    {
        mode: 'PRIMARY',
        title: 'Primary Stream',
    },
    {
        mode: 'GRID',
        title: 'Grid',
    },
    // {
    //     mode: 'DUAL_FEED',
    //     title: 'Dual Feed',
    // },
];

interface Props {
    currentMode: string;
    setLayoutMode: (s: string) => void;
    autoHideEnabled: boolean;
    toggleAutoHideUIEnabled: () => void;
}

export default function Menu({
    currentMode,
    setLayoutMode,
    autoHideEnabled,
    toggleAutoHideUIEnabled,
}: Props) {
    const {
        menuOpen,
        username,
        chatVisible,
        chatEnabled,
        videochatStatus,
        videochatJoined,
        videochatEnabled,
    } = useAppSelector((s) => ({
        menuOpen: s.app.menuOpen,
        username: s.user.name,
        chatVisible: s.chat.visible,
        videochatJoined: s.videochat.joined,
        videochatStatus: s.videochat.status,
        videochatEnabled: s.app.config?.videochatEnabled,
        chatEnabled: s.app.config?.chatEnabled,
    }));

    const dispatch = useAppDispatch();

    const closeMenu = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape' && menuOpen) {
                dispatch(toggleMenu());
            }
        },
        [menuOpen] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
        document.addEventListener('keydown', closeMenu);
        return () => {
            document.removeEventListener('keydown', closeMenu);
        };
    }, [closeMenu]);

    const [usernameEntered, setUsernameEntered] = useState(username);

    useEffect(() => {
        setUsernameEntered(username);
    }, [username]);

    const { lastReadMessageId, lastMessageId } = useAppSelector((state) => ({
        lastReadMessageId: state.chat.lastMessageReadId,
        lastMessageId:
            state.chat.messages && state.chat.messages.length
                ? state.chat.messages[state.chat.messages.length - 1].id
                : 'xyz',
    }));

    const hasUnread = lastReadMessageId !== lastMessageId;

    function renderTextChatOptions() {
        return (
            <>
                <div className="title">
                    <div
                        className={classNames('status', {
                            on: chatVisible,
                            off: !chatVisible,
                        })}
                    ></div>
                    Text Chat
                </div>
                <div className="menuRow">
                    <Button
                        value={`${chatVisible ? 'Hide' : 'Show'} Chat${
                            hasUnread ? ' [Unread Messages]' : ''
                        }`}
                        onClick={() => {
                            dispatch(toggleVisibility());
                            dispatch(toggleMenu());
                        }}
                    />
                </div>
            </>
        );
    }

    function renderVideoChatOptions() {
        return (
            <>
                <div className="title">
                    <div
                        className={classNames('status', videochatStatus)}
                    ></div>
                    Video Chat
                </div>
                <div className="menuRow">
                    <Button
                        value={`${
                            videochatStatus === 'on'
                                ? 'End Video Chat'
                                : videochatStatus === 'connecting'
                                ? 'Connecting...'
                                : 'Join Video Chat'
                        }`}
                        onClick={() => {
                            if (videochatStatus === 'connecting') return;

                            dispatch(setJoined(!videochatJoined));
                            dispatch(toggleMenu());
                        }}
                    />
                </div>
            </>
        );
    }

    function renderNameOptions() {
        return (
            <>
                <div className="title">Set Name</div>
                <div className="menuRow">
                    <Input
                        value={usernameEntered}
                        onChange={(e) => {
                            setUsernameEntered(e.target.value);
                        }}
                    />
                </div>
                <div className="menuRow">
                    <Button
                        disabled={usernameEntered === username}
                        value="Update Name"
                        onClick={() => {
                            dispatch(setName(usernameEntered));
                        }}
                    />
                </div>
            </>
        );
    }

    function renderMain() {
        return (
            <>
                {(chatEnabled || videochatEnabled) && renderNameOptions()}
                {chatEnabled && renderTextChatOptions()}
                {videochatEnabled && renderVideoChatOptions()}

                <div className="title">Display Mode</div>
                <div className="menuRow">
                    <div className="stream-modes">
                        {DISPLAY_MODES.map((m) => (
                            <div
                                className={classNames('mode', {
                                    active: currentMode === m.mode,
                                })}
                                key={m.mode}
                                onClick={() => {
                                    setLayoutMode(m.mode);
                                }}
                            >
                                {m.title}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="title">Interface</div>
                <div className="menuRow">
                    <div className="stream-modes">
                        <div
                            className={classNames('mode', {
                                active: autoHideEnabled,
                            })}
                            onClick={() => {
                                toggleAutoHideUIEnabled();
                            }}
                        >
                            Auto Hide UI
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div
                className={classNames('Menu-bg', { visible: menuOpen })}
                onClick={() => {
                    dispatch(toggleMenu());
                }}
            ></div>
            <div className={classNames('Menu', { visible: menuOpen })}>
                <Icon
                    className="close-menu-icon"
                    type="x"
                    onClick={() => {
                        dispatch(toggleMenu());
                    }}
                />
                {(chatEnabled || videochatEnabled) && !username ? (
                    <MenuIntro />
                ) : (
                    renderMain()
                )}
            </div>
        </>
    );
}
