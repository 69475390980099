import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getInitialState } from '../store';

export interface Stream {
    name: string;
    id: string;
}

export interface AppConfig {
    streams: Stream[];
    mainStreamId: string;
    websocketUrl: string;
    conferenceRoomName: string;
    previewsBaseurl: string;
    roomId: string;
    chatEnabled: boolean;
    videochatEnabled: boolean;
    title: string;
    logoUrl: string;
}

export interface AppState {
    menuOpen: boolean;
    config?: AppConfig;
    configLoaded: boolean;
    configPass: string;
    initialConfigLoadCompleted: boolean;
}

let initialState: AppState = getInitialState('app', {
    menuOpen: false,
    configLoaded: false,
    configPass: '',
    initialConfigLoadCompleted: false,
});

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toggleMenu: (state) => {
            state.menuOpen = !state.menuOpen;
        },
        setConfig: (state, action: PayloadAction<AppConfig>) => {
            state.config = action.payload;
        },
        setConfigPass: (state, { payload }: PayloadAction<string>) => {
            state.configPass = payload;
        },
        setInitialConfigLoadCompleted: (
            state,
            { payload }: PayloadAction<boolean>
        ) => {
            state.initialConfigLoadCompleted = payload;
        },
    },
});

export const {
    toggleMenu,
    setConfig,
    setConfigPass,
    setInitialConfigLoadCompleted,
} = appSlice.actions;
export default appSlice.reducer;
