import React from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase/app';

import './Chat.scss';

import minimiseIcon from '../../assets/img/minimise.svg';

import ChatIntro from './ChatIntro';
import ChatEntry from './ChatEntry';
import ChatMessage from './ChatMessage';
import { RootState } from '../../redux/store';
import {
    addMessage,
    ChatState,
    toggleVisibility,
} from '../../redux/features/chat';

import { setName } from '../../redux/features/user';
import Icon from '../Icon/Icon';

interface Props {
    roomId: string;
    username: string;
    chat: ChatState;
    dispatch: Function;
    menuOpen: boolean;
}

interface State {}

class Chat extends React.Component<Props, State> {
    messagesRef: React.RefObject<HTMLDivElement>;
    db: firebase.firestore.Firestore;
    chatRef: firebase.firestore.CollectionReference;
    disconnectFirebase?: Function;

    constructor(props: Props) {
        super(props);

        this.messagesRef = React.createRef<HTMLDivElement>();

        this.db = firebase.firestore();
        this.chatRef = this.db
            .collection('shoots')
            .doc(props.roomId)
            .collection('chat');
    }

    componentDidMount() {
        // todo: remove
        setTimeout(() => {
            this.messagesRef!.current?.scrollTo(
                0,
                this.messagesRef!.current?.scrollHeight
            );
        });

        this.connectToFirebase();

        // document.addEventListener('keydown', this.closeOnEscape);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // Todo: prevent if scrolled up
        if (
            (prevProps.chat.messages &&
                this.props.chat.messages &&
                prevProps.chat.messages.length <
                    this.props.chat.messages.length) ||
            (!prevProps.chat.messages && this.props.chat.messages)
        ) {
            this.messagesRef!.current?.scrollTo(
                0,
                this.messagesRef!.current?.scrollHeight
            );
        }
    }

    closeOnEscape = (e: KeyboardEvent) => {
        if (
            e.key === 'Escape' &&
            this.props.chat.visible &&
            !this.props.menuOpen
        ) {
            this.props.dispatch(toggleVisibility());
        }
    };

    onMessageSubmit = (message: string) => {
        this.chatRef.add({
            senderName: this.props.username,
            message: message,
            timestamp: new Date().toISOString(),
            created: firebase.firestore.Timestamp.now(),
        });
    };

    connectToFirebase = async () => {
        if (this.disconnectFirebase) return;

        this.disconnectFirebase = this.chatRef
            .orderBy('timestamp')
            .onSnapshot((querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    if (change.type === 'added') {
                        const doc = change.doc;
                        const d = doc.data();
                        this.props.dispatch(
                            addMessage({
                                id: doc.id,
                                senderName: d.senderName,
                                message: d.message,
                                timestamp: d.timestamp,
                            })
                        );
                    }
                });
            });
    };

    render() {
        const { chat, username } = this.props;

        return (
            <div id="Chat" className={chat.visible ? 'visible' : ''}>
                {!username ? (
                    <ChatIntro
                        onSubmit={(name) => {
                            this.props.dispatch(setName(name));
                        }}
                    />
                ) : (
                    <div className={'chatMain'}>
                        <div className="part header">
                            <div className="left">Chat</div>
                            <div className="right">
                                <Icon
                                    type="x"
                                    onClick={() => {
                                        this.props.dispatch(toggleVisibility());
                                    }}
                                />
                            </div>
                        </div>
                        <div className="part messages" ref={this.messagesRef}>
                            {chat.messages && chat.messages.length > 0 ? (
                                chat.messages.map((m) => (
                                    <ChatMessage
                                        {...m}
                                        fromSelf={
                                            m.senderName === this.props.username
                                        }
                                        key={m.id}
                                    />
                                ))
                            ) : (
                                <div className="no-messages-yet">
                                    No messages yet.
                                </div>
                            )}
                        </div>
                        <div className="part footer">
                            <ChatEntry onMessageSubmit={this.onMessageSubmit} />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    username: state.user.name,
    chat: state.chat,
    menuOpen: state.app.menuOpen,
});

export default connect(mapStateToProps)(Chat);
