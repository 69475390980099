import { WebRTCAdaptor } from './vendor/webrtc-adaptor';

export function initialise({ streamId, videoElement, callback, websocketURL }) {
    // let token, subscriberId, subscriberCode;
    var pc_config = {
        iceServers: [
            {
                urls: 'stun:global.stun.twilio.com:3478?transport=udp',
            },
        ],
    };

    var sdpConstraints = {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: true,
    };
    var mediaConstraints = {
        video: false,
        audio: false,
    };

    function playWebRTCVideo() {
        videoElement
            .play()
            .then(function () {
                if (callback.onPlayStarted) callback.onPlayStarted();
            })
            .catch(function (err) {
                console.error('Error playing video', err);
            });
    }

    let handledOnclose = false;

    // const initTime = Date.now();

    const webRTCAdaptor = new WebRTCAdaptor({
        websocket_url: websocketURL,
        mediaConstraints: mediaConstraints,
        peerconnection_config: pc_config,
        sdp_constraints: sdpConstraints,
        remoteVideoElement: videoElement,
        isPlayMode: true,
        debug: false,
        callback: function (info, data) {
            // console.log('info', info);

            if (info === 'initialized') {
                webRTCAdaptor.getStreamInfo(streamId);
            } else if (info === 'streamInformation') {
                // TODO: handle multiple stream options (is this where the different resolutions turn up?)
                // Adaptive bitrate removes original
                if (
                    callback.onStreamInfo &&
                    data.streamInfo &&
                    data.streamInfo[0]
                ) {
                    callback.onStreamInfo(data.streamInfo);
                }

                // Should now be hanndled by the calling onstreaminfo callback.
                // webRTCAdaptor.play(streamId, token, subscriberId, subscriberCode);
            } else if (info === 'play_started') {
                playWebRTCVideo();
            } else if (info === 'play_finished') {
                if (callback.onPlayFinished) callback.onPlayFinished();

                setTimeout(function () {
                    webRTCAdaptor.getStreamInfo(streamId);
                }, 3000);
            } else if (info === 'closed') {
                if (!handledOnclose) {
                    handledOnclose = true;
                    setTimeout(callback.onClose, 2000);
                }
            } else if (info === 'bitrateMeasurement') {
                // TODO handle bitrate settings
                if (
                    data.audioBitrate + data.videoBitrate >
                    data.targetBitrate
                ) {
                    // Show warning?
                }
            } else if (info === 'data_received') {
                //
            } else if (info === 'ix_video_stream_src') {
                if (callback.onVideoStreamSource)
                    callback.onVideoStreamSource(data);
            }
        },
        callbackError: function (error) {
            if (
                [
                    'no_stream_exist',
                    'not_initialized_yet',
                    'wserror',
                    'ws_connection_failed',
                ].includes(error)
            ) {
                if (error === 'no_stream_exist') {
                    console.log(
                        `StreamId "${streamId}" does not exist. Retrying in 2000ms...`
                    );
                }
                if (error === 'not_initialized_yet') {
                    console.log(`server not ready yet`);
                }
                if (error === 'wserror') {
                    console.log(`websocket error`);
                }

                if (!handledOnclose) {
                    handledOnclose = true;
                    setTimeout(callback.onClose, 2000);
                }
            } else if (['data_store_not_available'].includes(error)) {
                // we're already handling this
            } else {
                console.trace('error callback: ' + JSON.stringify(error));
            }
        },
    });

    window.antadaptor = webRTCAdaptor;
    return webRTCAdaptor;
}
