import React from 'react';

import fsicon from '../../assets/img/full-screen.svg';
import fscloseicon from '../../assets/img/full-screen-close.svg';

import './Fullscreen.scss';

interface Props {
    elemRef: React.RefObject<any>;
}

interface State {
    isFullscreen: boolean;
}

class Fullscreen extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isFullscreen: false,
        };
    }

    componentDidMount() {
        this.initFullScreen();
    }

    initFullScreen = () => {
        const fullscreenChangeHandler = (isFullscreen: boolean) => {
            this.setState({
                isFullscreen,
            });
        };

        document.addEventListener('fullscreenchange', () => {
            console.log('fullscreenchange', !!document.fullscreenElement);
            fullscreenChangeHandler(!!document.fullscreenElement);
        });

        document.addEventListener('webkitfullscreenchange', () => {
            // @ts-ignore
            console.log('webkitfullscreenchange', document.webkitIsFullScreen);
            // @ts-ignore
            fullscreenChangeHandler(document.webkitIsFullScreen);
        });
    };

    toggleFullscreen = () => {
        const elem = this.props.elemRef.current;

        if (elem.requestFullscreen) {
            if (!document.fullscreenElement) {
                console.log('entering fullscreen');
                elem.requestFullscreen().catch((err: Error) => {
                    console.error(err);
                });
            } else {
                console.log('exiting fullscreen');
                document.exitFullscreen();
            }
        } else if (elem.webkitRequestFullscreen) {
            // @ts-ignore
            if (!document.webkitIsFullScreen) {
                console.log('entering fullscreen');
                elem.webkitRequestFullscreen();
            } else {
                console.log('exiting fullscreen');
                // @ts-ignore
                document.webkitExitFullscreen();
            }
        }
    };

    render() {
        const { isFullscreen } = this.state;

        return (
            <div className="Fullscreen" onClick={this.toggleFullscreen}>
                {isFullscreen ? (
                    <img src={fscloseicon} alt="Full Screen" />
                ) : (
                    <img src={fsicon} alt="Full Screen" />
                )}
            </div>
        );
    }
}

export default Fullscreen;
