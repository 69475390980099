import { DailyParticipant, DailyTrackState } from '@daily-co/daily-js';
import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import { useRef } from 'react';
import Icon from '../../Icon/Icon';
import { isLocal } from '../utils';

import './Tile.scss';

interface Props {
    id: string;
    participant: DailyParticipant;
    onClick?: () => void;
}

export default function Tile(props: Props) {
    const videoEl = useRef<HTMLVideoElement>(null);
    const audioEl = useRef<HTMLAudioElement>(null);

    const videoTrackState = props.participant.tracks.video;
    const audioTrackState = props.participant.tracks.audio;

    const name = props.participant.user_name;

    const videoTrack = useMemo(() => {
        return videoTrackState && videoTrackState.state === 'playable'
            ? videoTrackState.track
            : null;
    }, [videoTrackState]);

    const audioTrack = useMemo(() => {
        return audioTrackState && audioTrackState.state === 'playable'
            ? audioTrackState.track
            : null;
    }, [audioTrackState]);

    /**
     * When video track changes, update video srcObject
     */
    useEffect(() => {
        videoTrack &&
            videoEl.current &&
            (videoEl.current.srcObject = new MediaStream([videoTrack]));
    }, [videoTrack]);

    /**
     * When audio track changes, update audio srcObject
     */
    useEffect(() => {
        audioTrack &&
            audioEl.current &&
            (audioEl.current.srcObject = new MediaStream([audioTrack]));
    }, [audioTrack]);

    function getVideoComponent() {
        return videoTrack && <video autoPlay muted playsInline ref={videoEl} />;
    }

    function getAudioComponent() {
        return (
            !isLocal(props.id) &&
            audioTrack && <audio autoPlay playsInline ref={audioEl} />
        );
    }

    console.log(props.id, videoTrackState.state);

    return (
        <div
            className={classNames(
                'VideoChat__Tile',
                'video_' + videoTrackState.state
            )}
            data-id={props.id}
        >
            {getVideoComponent()}
            {getAudioComponent()}
            <div className="name">
                {/* 
                {['off', 'blocked'].includes(videoTrackState.state)
                    ? '[Camera Off] '
                    : ''} */}
                {name}
            </div>
            {['off', 'blocked'].includes(audioTrackState.state) && (
                <Icon
                    className="muted"
                    type="mic-off"
                    size={24}
                    colorScheme="red"
                />
            )}
        </div>
    );
}
