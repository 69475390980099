import { KeyboardEvent, useState } from 'react';
import { connect } from 'react-redux';
import { setConfigPass } from '../../redux/features/app';
import { setName } from '../../redux/features/user';
import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import Button from '../Form/Button';
import Input from '../Form/Input';

import './PasswordPrompt.scss';

export default function PasswordPrompt() {
    const dispatch = useAppDispatch();

    const [passwordInput, setPasswordInput] = useState('');
    const [nameInput, setNameInput] = useState('');
    const [error, setError] = useState('');

    const { logoUrl, initialConfigLoadCompleted } = useAppSelector((s) => ({
        logoUrl: s.app.config?.logoUrl,
        initialConfigLoadCompleted: s.app.initialConfigLoadCompleted,
    }));

    async function submit() {
        if (nameInput.length < 1) {
            setError('Please enter your name');
            return;
        }
        if (passwordInput.length < 1) {
            setError('Please enter a password');
            return;
        }

        setError('');

        const hash = window.location.hash.replace('#', '');
        const timestamp = Date.now();
        let host =
            window.location.hostname === 'localhost'
                ? 'http://localhost:3001'
                : '';
        let path =
            host +
            `/api/stream?id=${hash}&key=${passwordInput}&tx=${timestamp}`;

        const res = await fetch(path);

        if (res.status === 403) {
            setError('Invalid Password');
            // dispatch(setConfigPass(''));
            return;
        } else if (res.status !== 200) {
            setError('Something went wrong');
            return;
        }

        dispatch(setName(nameInput));
        dispatch(setConfigPass(passwordInput));
    }

    function onKeyDown(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            submit();
        }
    }

    return (
        <div className="PasswordPrompt">
            <div className="content-container">
                <div className="img-container">
                    <img
                        src={
                            initialConfigLoadCompleted
                                ? logoUrl || '/logos/shoot-hub-white.svg'
                                : ''
                        }
                        alt=""
                    />
                </div>

                <Input
                    colorStyle="dark"
                    value={nameInput}
                    onChange={(e) => {
                        setNameInput(e.target.value);
                    }}
                    onKeyDown={onKeyDown}
                    placeholder="What's your name?"
                />
                <br />
                <br />
                <hr
                    style={{
                        width: '50%',
                    }}
                />
                <br />
                <Input
                    colorStyle="dark"
                    type="password"
                    value={passwordInput}
                    onChange={(e) => {
                        setPasswordInput(e.target.value);
                    }}
                    onKeyDown={onKeyDown}
                    placeholder="Password"
                />
                <Button value="Submit" onClick={submit} />
                <div className="error">{error} &nbsp;</div>

                <div className="contact-info">
                    <a
                        href="https://shoothub.io"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <img
                            src="/logos/shoot-hub-white.svg"
                            alt=""
                            className="shoothub"
                        />
                    </a>
                    <br />
                    <a
                        href="mailto://info@shoothub.io"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        info@shoothub.io
                    </a>
                    <br />
                    <a
                        href="https://wa.me/00442045667655"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <img
                            src="/logos/whatsapp-128.png"
                            alt=""
                            className="wa"
                        />{' '}
                        Whatsapp
                    </a>
                </div>
            </div>
        </div>
    );
}
